import React from "react"
import { useState, useEffect } from "react";
import styled from "styled-components";

import Seo from "../../components/util/Seo"
// import Header from "../components/homePage/Header";
import NavbarSimple from "../../components/layout/NavbarSimple"
import Footer from "../../components/layout/Footer";
import { useI18next } from "gatsby-plugin-react-i18next";


// import darkwingVideo from '../assets/homePage_assets/darkwing.mp4'
import ToiaLogo from '../../assets/homePage_assets/Toia.png'
import IMSTKLogo from '../../assets/homePage_assets/IMSTK_color.png'
import SOFALogo from '../../assets/homePage_assets/SOFA.png'
import VirteasyLogo from '../../assets/homePage_assets/Virteasy+logo.png'
import FundamentalLogo from '../../assets/homePage_assets/Fundamental.png'
import AutodeskLogo from '../../assets/homePage_assets/Autodesk.png'

import UnderArm from '../../assets/use-cases_assets/0_underarm+glow_Compressed.jpg'


const useCases = () => {

  const { language } = useI18next()

  let Texts = {
    "BannerDiv": {
      "H1": {
        "en": "Use Cases",
        "fr": "Cas d'utilisation"
      },
      "P": {
        "en": "3D content interaction | Simulation-based skills training | Remote device operation | Academia & research",
        "fr": "Interaction avec le contenu en 3D | Formation basée sur la simulation | Utilisation d'appareils à distance | Universités et recherche"
      }
    },
    "Interaction": {
      "H2": {
        "en": "3D content interaction",
        "fr": "Interaction avec le contenu en 3D"
      },
      "H3": {
        "en": "Haptic content design has the potential to revolutionize creative fields with new and improved user interactions.",
        "fr": "La conception de contenu haptique a le potentiel de révolutionner les domaines créatifs grâce à des interactions nouvelles et améliorées avec l'utilisateur."
      },
      "P": {
        "en": "The future of content creation and consumption will be focused on making virtual experiences as immersive and photo realistic as possible. The new UX paradigms realized by haptic content will enable users to naturally navigate spatial environments and feel what they digitally produce, transforming the way we interact with media as well as the structure of product development systems, among other things.",
        "fr": "L'avenir de la création et de la consommation de contenu consistera à rendre les expériences virtuelles aussi immersives et photo réalistes que possible. Les nouveaux paradigmes UX réalisés par le contenu haptique permettront aux utilisateurs de naviguer naturellement dans des environnements spatiaux et de sentir ce qu'ils produisent numériquement, transformant ainsi la façon dont nous interagissons avec les médias ainsi que la structure des systèmes de développement de produits, entre autres choses."
      }
    },
    "Simulation": {
      "H2": {
        "en": "Simulation-based skills training",
        "fr": "Formation basée sur la simulation"
      },
      "H3": {
        "en": "The more authentic a simulated environment is, the better the results of training.¹",
        "fr": "Plus l'environnement simulé est authentique, meilleurs sont les résultats de la formation.¹"
      },
      "P": {
        "en": "Haptic feedback takes virtual simulation training a step further, facilitating skill mastery by replicating the sensations of real life scenarios. This allows trainees to run through high-stakes situations with zero risk, which is particularly important for skills that require very precise movements and tactile feedback, such as medical procedures, or dangerous practices, and military operations.",
        "fr": "Le retour d'information haptique permet d'aller plus loin dans la formation par simulation virtuelle, en facilitant la maîtrise des compétences par la reproduction des sensations des scénarios de la vie réelle. Cela permet aux stagiaires de se confronter à des situations à fort enjeu sans aucun risque, ce qui est particulièrement important pour les compétences qui nécessitent des mouvements très précis et un retour tactile, comme les procédures médicales, les pratiques dangereuses et les opérations militaires."
      }
    },
    "Remote": {
      "H2": {
        "en": "Remote device operation",
        "fr": "Fonctionnement de l'appareil à distance"
      },
      "H3": {
        "en": "Haptic controllers offer an intuitive way to supervise machinery with an added dimension of accuracy.",
        "fr": "Les contrôleurs haptiques offrent un moyen intuitif de superviser les machines avec une dimension de précision supplémentaire."
      },
      "P": {
        "en": "Haptic teleoperation in robotics combines the benefits of robotic manipulation with human mental abilities and handling strategies by enabling a human operator to feel interaction forces and intuitively control the forces exerted by a remotely-operated robot on its environment. This feedback is vital for delicate tasks involving the handling of fragile or dangerous objects or tasks requiring a high level of precision.",
        "fr": "La téléopération haptique en robotique combine les avantages de la manipulation robotique avec les capacités mentales et les stratégies de manipulation humaines en permettant à un opérateur humain de ressentir les forces d'interaction et de contrôler intuitivement les forces exercées par un robot télécommandé sur son environnement. Ce retour d'information est vital pour les tâches délicates impliquant la manipulation d'objets fragiles ou dangereux ou pour les tâches nécessitant un haut niveau de précision."
      }
    },
    "Academia": {
      "H2": {
        "en": "Academia & research",
        "fr": "Universités et recherche"
      },
      "H3": {
        "en": "Immerse in true-to-life simulated research environments at a fraction of the cost.",
        "fr": "Plongez dans des environnements de recherche simulés plus vrais que nature pour une fraction du coût."
      },
      "P": {
        "en": "Haptic technology enables researchers to conduct high-level studies in realistic virtual recreations of any setting, for any type of research — whether academic, clinical, or commercial. This level of immersion supports the discovery of meaningful insights with the accuracy of physical studies, minus considerable research costs and unpredictable environmental factors.",
        "fr": "La technologie haptique permet aux chercheurs de mener des études de haut niveau dans des recréations virtuelles réalistes de n'importe quel environnement, pour n'importe quel type de recherche - qu'elle soit universitaire, clinique ou commerciale. Ce niveau d'immersion permet de découvrir des informations significatives avec la même précision que les études physiques, sans les coûts de recherche considérables et les facteurs environnementaux imprévisibles."
      }
    },
    "Footnotes": {
      "P": {
        "en": "Olga Chernikova et al., “Simulation-Based Learning in Higher Education: A Meta-Analysis,” Review of Educational Research 90, no. 4 (2020): pp. 499-541, https://doi.org/10.3102/0034654320933544.",
        "fr": `Olga Chernikova et al, "Simulation-Based Learning in Higher Education : A Meta-Analysis", Review of Educational Research 90, no. 4 (2020) : pp. 499-541, https://doi.org/10.3102/0034654320933544.`
      }
    },
  }


  return (
      <UseCasesWrapper>
        <NavbarSimple></NavbarSimple>
        <BannerDiv>
          <Content>
            <H1>{Texts["BannerDiv"]["H1"][language]}</H1>
            <P className={'head'}>{Texts["BannerDiv"]["P"][language]}</P>

          <BannerIMG/>
          </Content>
        </BannerDiv>
        <BlackDiv>
          <Content>
            <Interaction>
              <H2>{Texts["Interaction"]["H2"][language]}</H2>
              <H3>{Texts["Interaction"]["H3"][language]}</H3>
              <P>{Texts["Interaction"]["P"][language]}</P>
              <LogoDiv>
                <LogoIMG src={ToiaLogo} size={'45px'}></LogoIMG>
                <LogoIMG src={IMSTKLogo} size={'50px'}></LogoIMG>
                <LogoIMG src={SOFALogo} size={'50px'}></LogoIMG>
              </LogoDiv>
            </Interaction>

            <Simulation>
              <H2>{Texts["Simulation"]["H2"][language]}</H2>
              <H3>{Texts["Simulation"]["H3"][language]}</H3>
              <P>{Texts["Simulation"]["P"][language]}</P>
              <LogoDiv>
                <LogoIMG src={VirteasyLogo} size={'50px'}></LogoIMG>
                <LogoIMG src={FundamentalLogo} size={'20px'}></LogoIMG>
              </LogoDiv>
            </Simulation>

            <Remote>
              <H2>{Texts["Remote"]["H2"][language]}</H2>
              <H3>{Texts["Remote"]["H3"][language]}</H3>
              <P>{Texts["Remote"]["P"][language]}</P>
            </Remote>

            <Academia>
              <H2>{Texts["Academia"]["H2"][language]}</H2>
              <H3>{Texts["Academia"]["H3"][language]}</H3>
              <P>{Texts["Academia"]["P"][language]}</P>
              <LogoIMG src={AutodeskLogo} size={'40px'}></LogoIMG>
            </Academia>

            <Footnotes>
              <Line/>
              <P>{Texts["Footnotes"]["P"][language]}</P>
            </Footnotes>

          </Content>
        </BlackDiv>
        <Footer></Footer>
      </UseCasesWrapper>
  )
}

const FooterStyle = styled(Footer)`
  background-color: white;
  z-index: 10;
`

const H1 = styled.h1`
  font-weight: 300;
  margin: 160px 0 20px 0;

`
const H2 = styled.h2`
  text-align: center;
  font-weight: 300;
  margin-top: 50px;
  font-size: calc(12px + 3vw);

`
const H3 = styled.h3`
  text-align: center;
  font-weight: 300;
  font-style: italic;
  color: #DCD1FA;
  margin: 30px 25% 25px 25%;

`
const P = styled.p`
  font-weight: 100;

  text-align: center;
  margin: 0 15% 40px 15%;
  &&.head {
    margin: 0 5% 40px 5%;

  }



`

const Content = styled.div`
  width: 80%;
  max-width: 1200px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  && p{
    /* margin: 0; */
    /* width: 90%;
    max-width: 1200px; */
    /* border-width: 1px;
    border-color: red; */

  }
`


const BannerDiv = styled.div`
  color: white;

  /* min-height: 600px; */
  min-height: 66vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: grey;
`

const BannerIMG = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: url(${UnderArm});
  background-size: cover;
  background-position: 25% 70%;
  opacity: 0.95;
`

const BlackDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Interaction = styled.div`
  color: white;

  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* margin-bottom: 30px; */
  padding-top: 60px;
  padding-bottom: 60px;
  /* border-width: 0 0 5px 0;
  border-radius: 5px;
  border-color: #313496; */
`

const Simulation = styled.div`
  color: white;

  min-height: 600px;
  display: inline-flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
  /* border-width: 0 0 5px 0;
  border-radius: 5px;
  border-color: #313496; */
`

const Remote = styled.div`
  color: white;
  min-height: 450px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
  /* border-width: 0 0 5px 0;
  border-radius: 5px;
  border-color: #313496; */
  && * {
    display: inline;
  }
`

const Academia = styled.div`
  color: white;

  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px; 
  border-width: 0 0 2px 0;
  border-radius: 5px;
  border-color: #DCD1FA;
  padding-bottom: 150px;

`
const LogoDiv = styled.div`
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  padding-bottom: 50px;
`
const LogoIMG = styled.img`
  height: ${props => props.size};
  max-height: 5vw;
`
const Line = styled.div`

`
const Footnotes = styled.div`
  color: white;
  padding-top: 20px;
  padding-bottom: 60px;
  font-size: 14px;
  font-weight: lighter;

`

const UseCasesWrapper = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  font-family: 'Kanit', sans-serif;

  width: 100vw;
  height: 100%;
  background-color: black;
  overscroll-behavior-x: none;

  
  && *{box-sizing: border-box;}
  && h3{
    font-size: 20px;
    font-weight: normal;
  }

  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */

  /* && * { margin: 0; padding: 0; box-sizing: border-box; opacity: 10}
  && .button-style-1{
    color: white;
    border-radius: 25px;
    height: 50px;
    width: 144px;
    border: solid 3px white;
  }
  && .button-disbled_style-1{
    color: grey;
    border-radius: 25px;
    height: 50px;
    width: 144px;
    border: solid 3px grey;
    cursor: default;

  } */
`


export default useCases;



export const Head = () => (
    <Seo title="Haply Robotics | technology touching lives" />
  )
  